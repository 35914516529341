import React, { Fragment } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import * as styles from '../Banner/banner.module.css'

export default function Banner (props) {
  const { desktop, mobile, alt } = props
  return (
    <Fragment>
      <div>
        <figure id={styles.desktopBanner}>
          <GatsbyImage image={desktop} alt={alt} />
          <div className={styles.frasedesktop}></div>
        </figure>
        <figure id={styles.mobileBanner}>
          <GatsbyImage image={mobile} alt={alt} />
          <div className={styles.frasemobile}></div>
        </figure>
      </div>
    </Fragment>
  )
}

Banner.propTypes = {
  desktop: PropTypes.object.isRequired,
  mobile: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired
}
